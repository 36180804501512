import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'lib-flexible'
import './fkexi'
import service from '@/utils/request.js'
import less from 'less'
import ElementUI from 'element-ui';
import '@/assets/theme/index.css'
import ECharts from 'vue-echarts'
import vueEsign from 'vue-esign'
import animate from "animate.css";
import wsConnection from '@/utils/socket.js'
import * as filters from './filter'
import VueRouter from 'vue-router'
Vue.prototype.$setWs = wsConnection;
import cal from '@/utils/calculation';
Vue.prototype.$cal = cal
Vue.prototype.$wsUrl = process.env.VUE_APP_BASE_WS 

Vue.use(vueEsign)
Vue.component('v-chart', ECharts)
Vue.use(ElementUI);
Vue.use(less)
// Vue.use(animated)

Vue.use(VueRouter)
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// import Vconsole from 'vconsole';
// new Vconsole();
Vue.prototype.$axios = service
Vue.prototype.$baseUrl = process.env.VUE_APP_BASE_API
Vue.prototype.$StaticBaseUrl = process.env.VUE_APP_BASE_STATIC
Vue.config.productionTip = false

Object.keys(filters).forEach(key=>{Vue.filter(key,filters[key])})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
