import service from '@/utils/request.js'
import Qs from 'qs'
//数据字典
export const getDict=params=>service.get('/api/sys/dict/getDictItems/'+params);
// 用户租户关系查询
export const UserTenantQueryByUsername = params=>service.get(`/api/config/mkSysuserTenant/queryByUsername`,{params});
// 登录
export const getLogin = params=>service.post(`/api/sys/mLogin`,params);
// 退出
export const getLoginOut = params=>service.post(`/api/sys/logout`,params);
// 查询用户可登录租户列表
export const queryTenantByUsername = params=>service.get(`/api/config/mkSysuserTenant/queryListByUsername`,{params});


//添加订单
export const addOrder = params=>service.post(`/api/order/mkOrder/add`,params);
//编辑订单
export const editOrder = params=>service.put(`/api/order/mkOrder/edit`,params);
//获取订单列表
export const getOrderList=params=>service({url:'/api/order/mkOrder/list', method: 'get', params:params});
//绑定手牌
export const bindTagSn = params=>service.put(`/api/order/mkOrder/bindTagSn`,params);
//通过id获取订单信息
export const getOrderById=params=>service.get('/api/order/mkOrder/list',{params});
//删除订单
export const delOrderById=params=>service.delete('/api/order/mkOrder/delete',{params});
//订单结账
export const payOrder = params=>service.post(`/api/cash/mkQuickCash/add`,params);
// 预支付
export const prePayOrder = params=>service.post(`/api/cash/mkQuickCash/prepay`,Qs.stringify(params));
// 打印
export const printOrder = params=>service.post(`/api/order/mkOrder/print`,Qs.stringify(params));
// 作废
export const cancelOrder = params=>service.put(`/api/order/mkOrder/cancel`, params);
// 条码支付收款
export const submitQrPayOrder = params=>service.post(`/api/cash/mkQuickCash/submitQrPay`,Qs.stringify(params));



//会员列表
export const getMemberList=params=>service.get('/api/customer/mkCustomer/list',{params});
//添加会员
export const addMember=params=>service.post('/api/customer/mkCustomer/add',params);
//编辑会员
export const editMember=params=>service.put('/api/customer/mkCustomer/edit',params);
//根据id查询会员
export const getMemberById=params=>service.get('/api/customer/mkCustomer/queryById',{params});


//查询会员已购会员卡
export const getMemberCard=params=>service.get('/api/card/mkCard/listByCustomerId',{params});
//会员已购会员卡总数
export const getMemberCardNum=params=>service.get('/api/card/mkCard/statByCustomerId',{params});
//已经购买品项
export const getMemberPro=params=>service.get('/api/order/mkOrderGoods/listHasUsableByCustomer',{params});
//品项可用次数
export const getMemberProNo=params=>service.get('/api/order/mkOrderGoods/statBalanceByCustomer',{params});

//服务员工
export const getStaff=params=>service.get('/api/sys/user/list',{params});

//品项列表
export const getGoods=params=>service.get('/api/goods/mkGoods/list',{params});
// 品项左边分类
export const getCategoryL=params=>service.get('/api/sys/dict/getDictItems/goods_sub_category',{params});
// 品项上边品牌
export const getBrandT=params=>service.get('/api/brand/mkBrand/list',{params});
// 品项上边大类
export const getCategoryT=params=>service.get('/api/goods/mkGoodsCatalog/list',{params});
// 查询品项打折后的价格
export const goodsQueryMemberPrice=params=>service.get('/api/goods/mkGoods/queryMemberPrice',{params});



//开卡选卡列表
export const getCards=params=>service.get('/api/card/mkCardIssue/list',{params});
//顾客会员卡
export const getMemberCards=params=>service.get('/api/card/mkCard/listByCustomerId',{params});
//查询客户产品对应可用的会员卡
export const queryByCustomerAndProduct=params=>service.get('/api/card/mkCard/queryByCustomerAndProduct',{params});
//购卡订单保存
export const addCardOrder=params=>service.post('/api/card/mkCardOrder/add',params);
//购卡订单列表
export const getCardsList=params=>service.get('/api/card/mkCardOrder/list',{params});


//购卡订单现金支付
export const cardOrderCashPay=params=>service.post('/api/card/mkCardOrder/cashPay',Qs.stringify(params));
//购卡订单现金支付
export const cardOrderPosPay=params=>service.post('/api/card/mkCardOrder/posPay',Qs.stringify(params));
//购卡订单支付预处理
export const cardOrderPrePay=params=>service.post('/api/card/mkCardOrder/prepay',Qs.stringify(params));
//购卡订单条码支付预处理
export const cardOrderSubmitQrPay=params=>service.post('/api/card/mkCardOrder/submitQrPay',Qs.stringify(params));


//购卡订单详情
export const queryCardOrder=params=>service.get('/api/card/mkCardOrder/queryById',{params});
//删除购卡订单
export const delCardOrder=params=>service.delete('/api/card/mkCardOrder/delete',{params});

// 服务员工查询
export const getStaffList=params=>service.get('/api/order/mkOrderItemAssistant/list',{params});
//预约列表
export const mkBookingList=params=>service.get('/api/customer/mkBooking/list',{params});
//添加预约
export const addBooking=params=>service.post('/api/customer/mkBooking/add',params);
//编辑预约
export const editBooking=params=>service.post('/api/customer/mkBooking/edit',params);
//删除预约
export const delBooking=params=>service.delete('/api/customer/mkBooking/delete',{params});

//短信相关
//发送自定义短信
export const sendSms=params=>service.post('/api/sms/customerSms/send',params);

// 报表
//当日销售情况
export const dailyAllSaleStat=params=>service.get('/api/report/saleReport/dailyAllSaleStat',{params});
//当日销售情况分类统计
export const dailySaleStat=params=>service.get('/api/report/saleReport/dailySaleStat',{params});
//商品销售排名
export const listGoodsSaleSummary=params=>service.get('/api/report/saleReport/listGoodsSaleSummary',{params});
// 顾客 
export const statCustomerOrder=params=>service.get('/api/report/saleReport/statCustomerOrder',{params});
// 销售 
export const pageGoodsSaleSummary=params=>service.get('/api/report/saleReport/pageGoodsSaleSummary',{params});
// 服务 
export const listDebitGoodsSummary=params=>service.get('/api/report/saleReport/listDebitGoodsSummary',{params});
// 储值卡 
export const listCardSaleSummary=params=>service.get('/api/report/saleReport/listCardSaleSummary',{params});

export const getHomeInfo = params=>service.get('/api/home/index', {params}); 
//签名
export const signature = params=>service.put('/api/order/mkOrder/signature', params);
export const saveSign = params=>service.post('/api/sys/common/upload', params);

//服务记录
export const getCustomerService = params=>service.get('/api/customer/mkCustomerService/queryById', {params}); 
// 标签列表
export const listResidualByCustomer = params=>service.get('/api/customer/mkCustomerTagTpl/listResidualByCustomer', {params}); 
// 增加标签
export const addSign = params=>service.post('/api/customer/mkCustomerTag/add', params);
// 删除标签
export const delCustomerTag = params=>service.delete('/api/customer/mkCustomerTag/delete', {params}); 

// 余额统计
// 卡余额
export const cardBalanceSummaryList = params=>service.get('/api/report/saleReport/cardBalanceSummaryList', {params});
// 卡余额明细
export const cardBalanceList = params=>service.get('/api/report/saleReport/cardBalanceList', {params});

// 计次卡余额
export const goodsBalanceSummaryList = params=>service.get('/api/report/saleReport/goodsBalanceSummaryList', {params});
// 计次卡余额明细
export const goodsBalanceList = params=>service.get('/api/report/saleReport/goodsBalanceList', {params});

// 服务项目
export const serviceList=params=>service.get('/api/service/mkService/list',{params});

// 服务属性计算
export const evlCommission = params=>service.post('/api/order/mkAssistantServiceItem/evaluateCommission', params);


// birt报表 responseType: 'blob'
export const ReportSalesSummer=params=>service.get('/api/report/saleReport/getSalesSummer',{params});
export const ReportSalesSummerFile=params=>service.get('/api/report/saleReport/getSalesSummerFile',{params});

