 //这里需要引入vuex
 import store from '../store';
 
 let wsConnection = {
   $ws: null,
   lockReturn: false,
   timeout: 20 * 1000,
   timeoutObj: null,
   timeoutNum: null,
   serverTimeoutObj: null,
   wsurl: null,
   WS_CONNECTING: 0,
   WS_OPEN: 1,
   WS_CLOSING: 2,
   WS_CLOSED: 3,
   //初始化webSocket长连接
   initWebSocket: function (wsurl) {
     console.log("initWebSocket", wsurl);

     if(this.$ws != null && 
        ( this.$ws.readyState == this.WS_CONNECTING ||  this.$ws.readyState == this.WS_OPEN) ){
        console.log("websocket already open", wsurl);
        return;
     }

     this.wsurl = wsurl;
     this.$ws = new WebSocket(wsurl);//写入地址 这里的地址可以在initWebSocket方法加入参数
     this.$ws.onopen = this.wsOpen;
     this.$ws.onclose = this.wsClose;
     this.$ws.onmessage = this.wsMsg;
     this.$ws.onerror = this.wsError;
     
   },
   //打开websocket
   wsOpen: function (e) {
     //开始websocket心跳
     wsConnection.startWsHeartbeat();
     console.log('ws success')
   },
   wsClose: function (e) {
     console.log('ws close', e)
     wsConnection.reconnect();
   },
   wsMsg: function (msg) {
     //每次接收到服务端消息后 重置websocket心跳
     // wsConnection.resetHeartbeat();
     //服务端发送来的消息存到vuex
     console.log("wsMsg", msg);
     store.commit('web_socket_msg', msg)
   },
   wsError: function (err) {
     console.log(err, 'ws error');
   },
   //重启websocket
   reconnect: function () {
     let _this = this;
     if (_this.lockReturn) {
       return;
     }
     _this.lockReturn = true;
     _this.timeoutNum && clearTimeout(_this.timeoutNum);
     _this.timeoutNum = setTimeout(function () {
       _this.initWebSocket(_this.wsurl);
       _this.lockReturn = false;
     }, 3000);
   },
   startWsHeartbeat: function () {
     let _this = this;
     _this.timeoutObj && clearTimeout(_this.timeoutObj);
     _this.serverTimeoutObj && clearTimeout(_this.serverTimeoutObj);
     _this.timeoutObj = setInterval(function () {
       //判断websocket当前状态
       try {
        _this.$ws.send("HeartBeat");
      } catch (err) {
        console.log("send failed (" + err.code + ")");
      }
     }, _this.timeout);
   },
   //重置websocket心跳
   resetHeartbeat: function () {
     let _this = this;
     clearTimeout(_this.timeoutObj);
     clearTimeout(_this.serverTimeoutObj);
     _this.startWsHeartbeat()
   }
 };
 
 //抛出websocket对象
 export default wsConnection