import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
    redirect:'/home',
    children:[
      {
        path: '/home',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home/index.vue')
      },
      {
        path: '/card-order',
        name: 'CardOrder',
        component: () => import(/* webpackChunkName: "card" */ '../views/Card/card-order/index.vue')
      },
      // {
      //   path: '/sign',
      //   name: 'Sign',
      //   component: () => import(/* webpackChunkName: "sign" */ '../views/Sign/index.vue')
      // },
      {
        path: '/billing',
        name: 'Billing',
        component: () => import(/* webpackChunkName: "billing" */ '../views/Billing/index.vue')
      },
      {
        path: '/card',
        name: 'Card',
        component: () => import(/* webpackChunkName: "billing" */ '../views/Card/index.vue')
      },
      {
        path: '/order',
        name: 'Order',
        component: () => import(/* webpackChunkName: "order" */ '../views/Billing/order/index.vue')
      },
      {
        path: '/appointment',
        name: 'Appointment',
        component: () => import(/* webpackChunkName: "appointment" */ '../views/Appointment/index.vue')
      },
      {
        path: '/report',
        name: 'Report',
        component: () => import(/* webpackChunkName: "report" */ '../views/Report/index.vue')
      },
      {
        path: '/member',
        name: 'Member',
        component: () => import(/* webpackChunkName: "member" */ '../views/Member/index.vue')
      },
      {
        path: '/member_list',
        name: 'MemberList',
        component: () => import(/* webpackChunkName: "member" */ '../views/Member/list.vue')
      },
      {
        path: '/member_info',
        name: 'MemberInfo',
        component: () => import(/* webpackChunkName: "member" */ '../views/Member/infos.vue')
      },
      {
        path: '/balance',
        name: 'Balance',
        component: () => import(/* webpackChunkName: "member" */ '../views/Balance/index.vue')
      },
      {
        path: '/balance/cardDetailList',
        name: 'cardDetailList',
        component: () => import(/* webpackChunkName: "member" */ '../views/Balance/cardDetailList/cardDetailList.vue')
      },
      {
        path: '/balance/goodsDetailList',
        name: 'goodsDetailList',
        component: () => import(/* webpackChunkName: "member" */ '../views/Balance/goodsDetailList/goodsDetailList.vue')
      },
      {
        path: '/perf',
        name: 'perf',
        component: () => import(/* webpackChunkName: "member" */ '../views/Perf/index.vue')
      },
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login/index.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(function(to, from, next) {

  if (!localStorage.getItem("token")) {
      if (to.path !== '/login') {
          return next('/login')
      }
  }
  next()
})

export default router
